import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      redirect: '/login',
      children: [
        // Dashboard
        {
          name: 'check-points',
          path: 'check-points',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'logs',
          path: 'logs',
          component: () => import('@/views/dashboard/pages/Logs'),
        },
        
        {
          name: 'settings',
          path: 'settings',
          component: () => import('@/views/dashboard/pages/Settings'),
        },
        // {
        //   name: 'limits',
        //   path: 'limits',
        //   component: () => import('@/views/dashboard/pages/Limits'),
        // },
        // {
        //   name: 'customer-report',
        //   path: 'customer-report',
        //   component: () => import('@/views/dashboard/pages/CustomerReport'),
        // },
        // {
        //   name: 'performance-results',
        //   path: 'performance-results',
        //   component: () => import('@/views/dashboard/pages/PerformanceResults'),
        // },
        // {
        //   name: 'period-report',
        //   path: 'period-report',
        //   component: () => import('@/views/dashboard/pages/PeriodReport'),
        // },
        // {
        //   name: 'alert-list',
        //   path: 'alert-list',
        //   component: () => import('@/views/dashboard/pages/AlertList'),
        // },
        // {
        //   name: 'transfer-speed',
        //   path: 'transfer-speed',
        //   component: () => import('@/views/dashboard/pages/TransferSpeed'),
        // },
        // {
        //   name: 'analysis',
        //   path: 'analysis',
        //   component: () => import('@/views/dashboard/pages/Analysis'),
        // },
      ]
    },

    {
      path: '/login',
      component: () => import('@/views/dashboard/pages/Login'),
    },

    {
      path: '/soon',
      component: () => import('@/views/dashboard/pages/soon'),
    },
    {
      path: '/*',
      component: () => import('@/views/dashboard/Index'),
      redirect: '/dashboard',
    },

  ],
})



